"use client"

import { create } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { UAParser } from "ua-parser-js"
import { ListingPlatform } from "@store-platform/types"

type ClientState = {
  isMobile: boolean
  userAgentBrowser?: UAParser.IBrowser
  userAgentDevice?: UAParser.IDevice
  userAgentOS?: UAParser.IOS
  isInstalled: boolean
  isMobileListingFooterOpen: boolean
  set: (
    partial:
      | ClientState
      | Partial<ClientState>
      | ((state: ClientState) => ClientState | Partial<ClientState>),
    replace?: boolean | undefined,
  ) => void
  platform: ListingPlatform | undefined
}

export const useClientState = create<ClientState>((set) => ({
  isMobile: false,
  isInstalled: false,
  isMobileListingFooterOpen: false,
  platform: undefined,
  set,
}))

// selector hooks
export const useSetClientState = () =>
  useClientState(useShallow((state) => state.set))

export const useIsMobile = () =>
  useClientState(useShallow((state) => state.isMobile))

export const useUserAgentBrowser = () =>
  useClientState(useShallow((state) => state.userAgentBrowser))

export const useUserAgentDevice = () =>
  useClientState(useShallow((state) => state.userAgentDevice))

export const useUserAgentOS = () =>
  useClientState(useShallow((state) => state.userAgentOS))

export const useIsInstalled = () =>
  useClientState(useShallow((state) => state.isInstalled))

export const useIsMobileListingFooterOpen = () =>
  useClientState(useShallow((state) => state.isMobileListingFooterOpen))

export const usePlatformState = () =>
  useClientState(useShallow((state) => state.platform))
